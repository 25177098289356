import { COOKIE_CONSENT_VERSION } from "@lib/CookieConsent";
import { isConsentCookieSet } from "@uxf/analytics/src/consent";
import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import { FC, memo, useCallback, useState } from "react";
import { CookieConsentBar } from "./CookieConsentBar";

const Component: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(!isConsentCookieSet(null, COOKIE_CONSENT_VERSION));

    const closeBar = useCallback(() => {
        setIsOpen(false);
    }, []);

    if (!isOpen) {
        return null;
    }

    return <CookieConsentBar closeBar={closeBar} />;
};

const Wrapper: FC = (props) => {
    const isMounted = useIsMounted();

    return isMounted ? <Component {...props} /> : null;
};

export const CookieConsent = memo(Wrapper);

CookieConsent.displayName = "CookieConsent";
