import { COOKIE_CONSENT_VERSION } from "@lib/CookieConsent";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Button } from "@ui/Button";
import { FlexBox } from "@ui/FlexBox";
import { Image } from "@ui/Image";
import { NewGhostButton } from "@ui/NewGhostButton";
import { TextH2, TextLarge } from "@ui/Text";
import { useModal } from "@utils/useModal";
import { updateGtmConsent } from "@uxf/analytics/gtm";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, memo, useCallback, useRef } from "react";
import { CookieConsentData } from "./CookieConsentModalForm";
import { CookieConsentFormContainer } from "./CookieConsentModalFormContainer";
import { ImageWrapper, Root } from "./styles";

export type CookieConsentBarProps = {
    closeBar: () => void;
};

const Component: FC<CookieConsentBarProps> = ({ closeBar }) => {
    const { t } = useTranslation();
    const [toggleModal, Modal, closeModal] = useModal();

    const scrollRef = useRef<HTMLDivElement>(null);

    const setConsentData = useCallback(
        (consentData: CookieConsentData) => () => {
            updateGtmConsent(
                {
                    ad_user_data: consentData.performance,
                    ad_storage: consentData.performance,
                    ad_personalization: consentData.performance,
                    analytics_storage: consentData.analytics,
                },
                COOKIE_CONSENT_VERSION,
            );
            closeBar();
        },
        [closeBar],
    );

    const openPreferences = useCallback(() => {
        toggleModal(<CookieConsentFormContainer closeModal={closeModal} setConsentData={setConsentData} />);
    }, [closeModal, setConsentData, toggleModal]);

    return (
        <>
            <Root tabIndex={0}>
                <FlexBox
                    backgroundColor="secondary"
                    boxHeight={[null, null, null, 320, 270]}
                    boxPosition="relative"
                    justifyContent="flex-end"
                    overFlow="hidden"
                    pl={[null, null, null, 475]}
                    ref={scrollRef}
                >
                    <Box
                        boxPosition="absolute"
                        style={{ top: 20, right: 20 }}
                        boxWidth="auto"
                        permanentlyHidden={{ condition: true, to: "md" }}
                    >
                        <NewGhostButton
                            iconName="cross"
                            light
                            onClick={setConsentData({
                                analytics: false,
                                performance: false,
                            })}
                            title={t("common:actions.close")}
                        />
                    </Box>
                    <ImageWrapper
                        backgroundColor="white"
                        boxHeight={575}
                        boxWidth={575}
                        flexGrow="0"
                        flexShrink="0"
                        alignItems="flex-end"
                        justifyContent="flex-end"
                        permanentlyHidden={{ condition: true, to: "lg" }}
                        pl={spacing(6)}
                        pr={spacing(7)}
                        pb={32}
                    >
                        <Image
                            imgProps={{ alt: t("common:labels.sousede") }}
                            height={360}
                            src="/static/images/landingPage/ilu-landingFamily.png"
                            width={369}
                        />
                    </ImageWrapper>
                    <FlexBox
                        flexDirection="column"
                        maxHeight="100vh"
                        maxWidth={960}
                        ml="auto"
                        mr="auto"
                        pb={[16, null, 32]}
                        pl={[16, null, 40]}
                        pr={[16, null, 32]}
                        pt={[16, null, 32]}
                        textColor="white"
                    >
                        <TextH2 mb={8} textVariant={["h3", "h2"]}>
                            {t("ui-cookies-consent:we-use-cookies")}
                        </TextH2>
                        <TextLarge mb={spacing(3)} textVariant={["medium", "large"]}>
                            {t("ui-cookies-consent:text1")}
                        </TextLarge>
                        <FlexBox flexDirection={["column", "row"]} justifyContent="flex-end" mt="auto">
                            <FlexBox boxWidth={["auto", 220]} flexDirection="column" mb={[16, 0]} mr={[0, 16]}>
                                <Button kind="outline" onClick={openPreferences} size="large" variant="white">
                                    {t("ui-cookies-consent:btn-text")}
                                </Button>
                            </FlexBox>
                            <FlexBox flexDirection="column" boxWidth={["auto", 200]}>
                                <Button
                                    kind="white"
                                    onClick={setConsentData({
                                        analytics: true,
                                        performance: true,
                                    })}
                                    size="large"
                                    variant="secondary"
                                >
                                    {t("ui-cookies-consent:confirm-btn")}
                                </Button>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </Root>
            <Modal width="extraWide" />
        </>
    );
};

export const CookieConsentBar = memo(Component);

CookieConsentBar.displayName = "CookieConsentBar";
