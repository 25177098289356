import {
    responsiveHiddenMixin,
    ResponsiveHiddenMixinProps,
    responsiveSrOnlyMixin,
    ResponsiveSrOnlyMixinProps,
} from "@styles/newMixins";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import styled, { css } from "styled-components";
import { SvgProps } from "./types";

export const Svg = styled("svg")<SvgProps>(
    ({ boxHeight, boxWidth, permanentlyHidden, visuallyHidden }) => css`
        ${Responsive.facePaintedMin({
            height: CssHelper.value(boxHeight),
            width: CssHelper.value(boxWidth),
        })}

        && {
            ${typeof visuallyHidden === "object"
                ? responsiveSrOnlyMixin(visuallyHidden as ResponsiveSrOnlyMixinProps)
                : visuallyHidden
                  ? responsiveSrOnlyMixin({ condition: visuallyHidden })
                  : null}

            ${typeof permanentlyHidden === "object"
                ? responsiveHiddenMixin(permanentlyHidden as ResponsiveHiddenMixinProps)
                : permanentlyHidden
                  ? responsiveHiddenMixin({ condition: permanentlyHidden })
                  : null};
        }
    `,
);
