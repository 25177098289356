import { IconSizeEnum } from "@config/icons";
import { IS_SELECTED_CLASSNAME } from "@styles/constants";
import { Icon } from "@ui/Icon";
import { Truncate } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import clsx from "clsx";
import { FC } from "react";
import { Root } from "./styles";
import { DropdownItemProps } from "./types";

export const DropdownItem: FC<DropdownItemProps> = (props) => {
    const { children, className, dataTestId, disabled, endComponent, icon, iconTitle, isActive, ...restProps } = props;

    return (
        <Root
            disabled={disabled}
            className={clsx(className, isActive && IS_SELECTED_CLASSNAME)}
            role="listitem"
            tabIndex={0}
            {...restProps}
            data-testid={dataTestId}
        >
            {!!icon && (
                <Icon
                    aria-label={iconTitle}
                    className="icon"
                    name={icon}
                    mr={spacing(1.5)}
                    size={IconSizeEnum.medium}
                />
            )}
            <Truncate>{children}</Truncate>
            {!!endComponent && <div className="end-component">{endComponent}</div>}
        </Root>
    );
};
