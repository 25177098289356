import { readConsentFromCookie } from "@uxf/analytics/consent";
import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import dynamic from "next/dynamic";
import { FC, memo, useMemo } from "react";
import { CookieConsentData, CookieConsentModalFormProps } from "./CookieConsentModalForm";

interface Props {
    setConsentData: (consentData: CookieConsentData) => () => void;
    closeModal: () => void;
}

const CookieConsentModalForm = dynamic<CookieConsentModalFormProps>(
    () => import("./CookieConsentModalForm").then((component) => component.CookieConsentModalForm),
    {
        ssr: false,
    },
);

const Component: FC<Props> = ({ setConsentData }) => {
    const initialValues: CookieConsentData = useMemo(() => {
        const consentData = readConsentFromCookie(null);

        return {
            analytics: typeof consentData.analytics_storage === "boolean" && consentData.analytics_storage,
            performance: typeof consentData.ad_storage === "boolean" && consentData.ad_storage,
        };
    }, []);

    return <CookieConsentModalForm setConsentData={setConsentData} initialValues={initialValues} />;
};

const Wrapper: FC<Props> = (props) => {
    const isMounted = useIsMounted();

    return isMounted ? <Component {...props} /> : null;
};

export const CookieConsentFormContainer = memo(Wrapper);

CookieConsentFormContainer.displayName = "CookieConsentFormContainer";
