import { paddingMixin } from "@styles/newMixins";
import { Box, IBoxProps } from "@ui/Box";
import { FlexBox, FlexBoxProps } from "@ui/FlexBox";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import { spacing } from "@uxf/styles/units/spacing";
import { buttonReset } from "@uxf_base/styles/mixins";
import { rem } from "polished";
import styled, { css } from "styled-components";

export const Root = styled(Box)<IBoxProps>(
    ({ theme }) => css`
        z-index: ${theme.zIndex.navBar};
    `,
);

export const Content = styled(FlexBox)<FlexBoxProps>(
    ({ theme }) => css`
        border-bottom: ${CssHelper.withUnit(theme.border.default, "px")} solid ${theme.color.palette.border};
    `,
);

export const ToolsDesktop = styled(FlexBox)<FlexBoxProps>(
    ({ theme }) => css`
        ${Responsive.min(theme.breakpoint.lg)} {
            border-left: ${CssHelper.withUnit(theme.border.default, "px")} solid ${theme.color.palette.border};
        }
    `,
);

export const ToolsMobile = styled(FlexBox)<FlexBoxProps>(
    ({ theme }) => css`
        border-left: ${CssHelper.withUnit(theme.border.default, "px")} solid ${theme.color.palette.border};
    `,
);

export const LanguageSwitcherWrapper = styled(FlexBox)<FlexBoxProps>(
    ({ theme }) => css`
        border-left: ${CssHelper.withUnit(theme.border.default, "px")} solid ${theme.color.palette.border};
        display: flex;
        align-items: stretch;
    `,
);

export const LanguageSwitcherTrigger = styled.button`
    ${buttonReset};

    ${paddingMixin({
        pt: spacing(1),
        pr: [spacing(1), null, spacing(3)],
        pb: spacing(1),
        pl: [spacing(1), null, spacing(3)],
    })}

    transition: opacity 150ms linear;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${rem(spacing(1))};
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }

    &:focus,
    &:active {
        outline: none;
    }
`;

export const LanguageSwitcherDropdown = styled.div(
    ({ theme }) => css`
        padding: ${rem(spacing(0.5))} ${rem(spacing(2))};
        background: ${theme.color.palette.white};
        box-shadow: ${theme.shadow.dropdown};
        z-index: ${theme.zIndex.dropdown};
    `,
);

export const LanguageSwitcherItem = styled.button(
    ({ theme }) => css`
        ${buttonReset};

        min-width: 200px;
        padding-block: ${rem(spacing(1.5))};
        display: flex;
        align-items: center;
        gap: ${rem(spacing(2))};
        cursor: pointer;

        &:hover {
            p {
                opacity: 0.6;
            }
        }

        &:focus,
        &:active {
            outline: none;
        }

        &:not(:last-child) {
            border-bottom: ${CssHelper.withUnit(theme.border.default, "px")} solid ${theme.color.palette.border};
        }

        p {
            transition: opacity 150ms linear;
        }

        .check {
            margin-left: auto;
        }
    `,
);
