import { theme } from "@styles/theme";
import { Box } from "@ui/Box";
import styled from "styled-components";
import { IContainerProps } from "./types";

export const Container = styled(Box)<IContainerProps>``;

Container.defaultProps = {
    maxWidth: Object.values(theme.container).slice(0, 5),
    ml: "auto",
    mr: "auto",
    pl: theme.spacing.pageHorizontal,
    pr: theme.spacing.pageHorizontal,
};

export const PageContainer = styled(Container)<IContainerProps>``;

PageContainer.defaultProps = {
    maxWidth: Object.values(theme.container).slice(0, 5),
    pt: theme.spacing.pageTop,
};

export const LandingPageContainer = styled(Container)<IContainerProps>``;

LandingPageContainer.defaultProps = {
    maxWidth: Object.values(theme.container),
};
