import {
    DISABLED_OPACITY,
    FOCUS_VISIBLE_CLASSNAME,
    IS_HOVERABLE_CLASSNAME,
    IS_SELECTED_CLASSNAME,
} from "@styles/constants";
import { NewClickable } from "@ui/NewClickable";
import { CssHelper } from "@utils/CssHelper";
import { getThemeColor } from "@utils/styling";
import { spacing } from "@uxf/styles/units/spacing";
import tint from "polished/lib/color/tint";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { RootProps } from "./types";

export const Root = styled(NewClickable)<RootProps>(
    ({ variant, theme }) => css`
        align-items: center;
        color: ${variant && getThemeColor(theme, variant) ? getThemeColor(theme, variant) : theme.color.palette.text};
        display: flex;
        font-size: ${rem(theme.typography.variant.medium.fontSize)};
        font-weight: ${theme.typography.fontWeight.medium};
        height: ${rem(theme.size.dropdown + theme.border.thick)};
        justify-content: flex-start;
        letter-spacing: ${theme.typography.letterSpacing};
        line-height: ${rem(theme.typography.variant.medium.lineHeight)};
        list-style: none;
        min-width: 0;
        overflow: hidden;
        padding: ${rem(spacing(0.5))} ${rem(spacing(1.5))};
        width: 100%;
        cursor: pointer;

        & > .icon {
            flex: 0 0 auto;
        }

        & > .end-component {
            flex: 0 0 auto;
            margin-left: ${rem(spacing(1.5))};
        }

        & + * {
            border-top: ${CssHelper.withUnit(theme.border.thick, "px")} solid ${theme.color.palette.bgLight};
        }

        &:first-child {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
            height: ${rem(theme.size.dropdown)};
        }

        &:last-child {
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;
        }

        &[aria-disabled="true"] {
            opacity: ${DISABLED_OPACITY};
            outline-style: none;
            pointer-events: none;
        }

        &.${IS_HOVERABLE_CLASSNAME} {
            &:hover,
            &:active {
                background-color: ${variant && getThemeColor(theme, variant)
                    ? getThemeColor(theme, variant)
                    : theme.color.palette.secondary};
                color: ${theme.color.palette.white};
            }
        }

        &:focus {
            outline-style: none;
        }

        &.${FOCUS_VISIBLE_CLASSNAME} {
            box-shadow: ${theme.shadow.buttonFocus};
        }

        &.${IS_SELECTED_CLASSNAME} {
            background-color: ${tint(0.9, theme.color.palette.secondary)};
        }
    `,
);

export const Menu = styled("ul")(
    ({ theme }) => css`
        background-color: ${theme.color.palette.white};
        border-radius: ${rem(theme.borderRadius.default)};
        box-shadow: ${theme.shadow.dropdown};
        list-style: none;
        margin: 0;
        max-height: ${rem(400)};
        overflow-y: auto;
        padding: 0;
        min-width: ${rem(220)};
        /*TODO set to min-width?*/
        z-index: ${theme.zIndex.dropdown};
    `,
);
