import { useTranslation } from "@translations/use-translation";
import { GhostButton } from "@ui/GhostButton";
import { ToggleLayerBase } from "@ui/ToogleLayerBase";
import { cloneElement, FC, useCallback, useState } from "react";
import { RenderLayer } from "react-laag/dist/ToggleLayer/types";
import { DropdownItem } from "./DropdownItem";
import { Menu } from "./styles";
import { DropdownProps } from "./types";

export const Dropdown: FC<DropdownProps> = (props) => {
    const { t } = useTranslation();
    const {
        disabled,
        dataTestId,
        id,
        items,
        title = t("ui-dropdown:next"),
        titleOpen = t("ui-dropdown:next"),
        triggerComponent = <GhostButton name="more" />,
        triggerRef,
        ...restProps
    } = props;
    const hasItems = !!items && items.length > 0;
    const idPrefix = `dropdown--${id}`;

    const [wrappedDisabled, setWrappedDisabled] = useState(disabled);

    const renderLayer: RenderLayer = useCallback(
        ({ close, isOpen, layerProps }) => {
            const handleClick = async (callback: any) => {
                if (callback) {
                    setWrappedDisabled(true);
                    try {
                        await callback();
                    } finally {
                        setWrappedDisabled(disabled);
                    }
                }
                close();
            };

            return isOpen && hasItems ? (
                <Menu id={idPrefix} ref={layerProps.ref} style={layerProps.style}>
                    {items?.map((item, index) => {
                        const { icon, onClick, title: itemTitle, ...restItemProps } = item;
                        return (
                            <DropdownItem
                                key={`dropdown-item--${index}`}
                                icon={icon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClick(onClick);
                                }}
                                {...restItemProps}
                            >
                                {itemTitle}
                            </DropdownItem>
                        );
                    })}
                </Menu>
            ) : null;
        },
        [disabled, hasItems, idPrefix, items],
    );

    return (
        <ToggleLayerBase
            renderLayer={renderLayer}
            triggerComponent={cloneElement(triggerComponent, {
                "aria-controls": idPrefix,
                "aria-haspopup": hasItems,
                disabled: wrappedDisabled ?? !hasItems,
                ["data-testid"]: dataTestId,
                id: `${idPrefix}--trigger`,
            })}
            triggerRef={triggerRef}
            title={title}
            titleOpen={titleOpen}
            {...restProps}
        />
    );
};
