import { GhostButton } from "@ui/GhostButton";
import { composeRefs } from "@utils/composeRefs";
import React, { cloneElement, FC } from "react";
import { ToggleLayer } from "react-laag";
import ResizeObserver from "resize-observer-polyfill";
import { IToggleLayerBaseProps } from "./types";

export const ToggleLayerBase: FC<IToggleLayerBaseProps> = (props) => {
    const {
        onClick,
        placementProps,
        renderLayer,
        title,
        titleOpen,
        toggleLayerProps,
        triggerComponent = <GhostButton name="more" />,
        triggerRef,
        ...restProps
    } = props;
    return renderLayer ? (
        <ToggleLayer
            closeOnOutsideClick
            closeOnDisappear="partial"
            renderLayer={renderLayer}
            {...toggleLayerProps}
            placement={{
                anchor: "BOTTOM_RIGHT",
                autoAdjust: true,
                snapToAnchor: false,
                triggerOffset: 8,
                scrollOffset: 16,
                preferX: "LEFT",
                ...placementProps,
            }}
            ResizeObserver={ResizeObserver}
        >
            {({ isOpen, triggerRef: innerTriggerRef, toggle }) =>
                cloneElement(triggerComponent, {
                    "aria-expanded": isOpen,
                    ref: composeRefs(innerTriggerRef, triggerRef),
                    title: isOpen ? titleOpen : title,
                    ...restProps,
                    onClick: (e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        toggle();
                        if (onClick) {
                            onClick(e);
                        }
                    },
                })
            }
        </ToggleLayer>
    ) : null;
};
