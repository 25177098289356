import { FlexBox, FlexBoxProps } from "@ui/FlexBox";
import { transparentize } from "polished";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export const Root = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        background-color: ${transparentize(0.5, theme.color.palette.black)};
        bottom: 0;
        left: 0;
        overflow-y: auto;
        position: fixed;
        right: 0;
        top: 0;
        z-index: ${theme.zIndex.modal - 1};

        &:focus {
            outline: none;
        }
    `,
);

export const ImageWrapper = styled(FlexBox)<FlexBoxProps>`
    position: absolute;
    left: -100px;
    top: calc(50% - 287px);
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
`;
